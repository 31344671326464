var render = function () {
  var _obj, _obj$1
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar", class: { open: _vm.isSidebarOpen } },
    [
      _vm.isSidebarOpen
        ? _c("div", {
            staticClass: "sidebar__background-close",
            on: {
              click: function ($event) {
                return _vm.$store.commit("toggleSidebar")
              },
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "sidebar__content d-md-none" }, [
        _c("div", { staticClass: "sidebar__profile" }, [
          _c("div", { staticClass: "flex a-center" }, [
            _c("div", {
              staticClass: "sidebar__profile_name",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("Hello") + " " + _vm.profile.first_name
                ),
              },
              on: { click: _vm.openProfile },
            }),
          ]),
        ]),
        _c("div", { staticClass: "header-company__name d-block" }, [
          _c("div", { staticClass: "select-box-sidebar" }, [
            _c(
              "header",
              {
                staticClass: "select-box-sidebar__header",
                class: { pointer: _vm.showTenantSelect },
                on: { click: _vm.toggleTenant },
              },
              [
                _c("section", { staticClass: "flex j-start a-center" }, [
                  _c("div", { staticClass: "flex" }, [
                    _c("span", {
                      staticClass: "trim",
                      domProps: {
                        textContent: _vm._s(
                          _vm.tenantName
                            ? _vm.tenantName
                            : _vm.$t("All tenants")
                        ),
                      },
                    }),
                  ]),
                  _c("span", {
                    style:
                      ((_obj = { opacity: 0.5 }),
                      (_obj["padding-inline-start"] = "10px"),
                      _obj),
                    domProps: { textContent: _vm._s(_vm.tenantName) },
                  }),
                ]),
                _vm.showTenantSelect
                  ? _c("svg-icon", {
                      staticClass: "ic-white m-i-start-1ch",
                      class: { isOpened: _vm.headerTenantOpen },
                      attrs: {
                        fill: "#fff",
                        name: "ic-arrow-down",
                        width: "14",
                        height: "9",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
        _vm.showTenantSelect
          ? _c(
              "ul",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.headerTenantOpen,
                    expression: "headerTenantOpen",
                  },
                ],
                staticClass: "option-container",
              },
              [
                _vm.isAdmin
                  ? _c(
                      "li",
                      {
                        class: { active: !_vm.tenantName },
                        on: {
                          click: function ($event) {
                            _vm.tenantName = null
                          },
                        },
                      },
                      [
                        _c("section", { staticClass: "flex a-center bet" }, [
                          _c("section", { staticClass: "flex bet" }, [
                            _c("div", [
                              _c("span", {
                                class: { selected: !_vm.tenantName },
                                domProps: {
                                  textContent: _vm._s(_vm.$t("All tenants")),
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._l(_vm.tenantsOptions, function (option, optionIndex) {
                  return _c(
                    "li",
                    {
                      key: optionIndex,
                      class: { active: _vm.tenantName === option.value },
                      on: {
                        click: function ($event) {
                          _vm.tenantName = option.value
                        },
                      },
                    },
                    [
                      _c("section", { staticClass: "flex a-center bet" }, [
                        _c("section", { staticClass: "flex bet" }, [
                          _c("div", [
                            _c("span", {
                              class: {
                                selected: _vm.tenantName === option.value,
                              },
                              domProps: { textContent: _vm._s(option.name) },
                            }),
                          ]),
                          _c("span", {
                            staticClass: "id",
                            domProps: { textContent: _vm._s(option.value) },
                          }),
                        ]),
                      ]),
                    ]
                  )
                }),
              ],
              2
            )
          : _vm._e(),
        _c("div", { staticClass: "header-company__name d-block" }, [
          _c("div", { staticClass: "select-box-sidebar" }, [
            _c(
              "header",
              {
                staticClass: "select-box-sidebar__header",
                class: { pointer: _vm.hasMoreThanOneRestaurant },
                on: { click: _vm.toggleCompany },
              },
              [
                _c("section", { staticClass: "flex j-start a-center" }, [
                  _c("div", { staticClass: "flex" }, [
                    _vm.restaurant && _vm.restaurant.icon
                      ? _c("img", {
                          staticClass: "logo-select-box mie12",
                          attrs: { src: _vm.restaurant.icon, alt: "" },
                        })
                      : _vm._e(),
                    _c("span", {
                      staticClass: "trim",
                      domProps: {
                        textContent: _vm._s(
                          _vm.restaurant
                            ? _vm.restaurant.name
                            : _vm.$t("All restaurants")
                        ),
                      },
                    }),
                  ]),
                  _c("span", {
                    style:
                      ((_obj$1 = { opacity: 0.5 }),
                      (_obj$1["padding-inline-start"] = "10px"),
                      _obj$1),
                    domProps: {
                      textContent: _vm._s(
                        _vm.currRestaurantOpt ? _vm.currRestaurantOpt.value : ""
                      ),
                    },
                  }),
                ]),
                _vm.hasMoreThanOneRestaurant
                  ? _c("svg-icon", {
                      staticClass: "ic-white m-i-start-1ch",
                      class: { isOpened: _vm.headerCompanyOpen },
                      attrs: {
                        fill: "#fff",
                        name: "ic-arrow-down",
                        width: "14",
                        height: "9",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
        _vm.hasMoreThanOneRestaurant
          ? _c(
              "ul",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.headerCompanyOpen,
                    expression: "headerCompanyOpen",
                  },
                ],
                staticClass: "option-container",
              },
              [
                _vm.headerCompanyOpen
                  ? _c(
                      "li",
                      { staticClass: "filter-by-container-side" },
                      [
                        _c("filter-by", {
                          attrs: { opacity: 0.8 },
                          on: { emitFilter: _vm.filterRestaurant },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.hasMoreThanOneRestaurant
                  ? _c(
                      "li",
                      {
                        class: { active: _vm.restaurantId === 0 },
                        on: {
                          click: function ($event) {
                            _vm.restaurantId = 0
                          },
                        },
                      },
                      [
                        _c("section", { staticClass: "flex a-center bet" }, [
                          _c("section", { staticClass: "flex bet" }, [
                            _c("div", [
                              _c("span", {
                                class: { selected: _vm.restaurantId === 0 },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("All restaurants")
                                  ),
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._l(
                  _vm.restaurantsOptionsSearch,
                  function (option, optionIndex) {
                    return _c(
                      "li",
                      {
                        key: optionIndex,
                        class: { active: _vm.restaurantId === option.value },
                        on: {
                          click: function ($event) {
                            _vm.restaurantId = option.value
                          },
                        },
                      },
                      [
                        option.label && option.sub
                          ? _c("div", { staticClass: "sub-item" })
                          : _c(
                              "section",
                              { staticClass: "flex a-center bet" },
                              [
                                _c("section", { staticClass: "flex bet" }, [
                                  _c("div", [
                                    option.img
                                      ? _c("img", {
                                          attrs: { src: option.img, alt: "" },
                                        })
                                      : _vm._e(),
                                    _c("span", {
                                      class: {
                                        selected:
                                          _vm.restaurantId === option.value,
                                      },
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t(option.label || option)
                                        ),
                                      },
                                    }),
                                  ]),
                                  _c("span", {
                                    staticClass: "id",
                                    domProps: {
                                      textContent: _vm._s(option.value),
                                    },
                                  }),
                                ]),
                              ]
                            ),
                      ]
                    )
                  }
                ),
              ],
              2
            )
          : _vm._e(),
        _c(
          "nav",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  !_vm.headerCompanyOpen || _vm.restaurantsOptions.length <= 1,
                expression:
                  "!headerCompanyOpen || restaurantsOptions.length <= 1",
              },
            ],
            staticClass: "sidebar-menu disable-scrollbars",
          },
          [
            _vm.hasSelectedRestaurant
              ? _c(
                  "ul",
                  { staticClass: "sidebar-menu__list" },
                  [
                    _vm._l(_vm.routerLinkList, function (routerLink) {
                      var _obj
                      return [
                        routerLink.visit
                          ? _c(
                              "li",
                              {
                                key: routerLink.to,
                                staticClass: "sidebar-menu__item",
                                class:
                                  ((_obj = {
                                    active: _vm.menuItemIsActive(routerLink.to),
                                  }),
                                  (_obj[routerLink.class] = routerLink.class),
                                  _obj),
                                attrs: { title: _vm.$t(routerLink.name) },
                              },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "sidebar-menu__link",
                                    attrs: { to: routerLink.to },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "sidebar-menu__img" },
                                      [
                                        _c("svg-icon", {
                                          staticClass: "ic-dashboard",
                                          attrs: {
                                            name: routerLink.svg,
                                            width: "24",
                                            height: "24",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "sidebar-menu__text flex a-center",
                                      },
                                      [
                                        _c("div", {
                                          staticClass: "text",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(routerLink.name)
                                            ),
                                          },
                                        }),
                                        routerLink.count
                                          ? _c("div", {
                                              staticClass: "count",
                                              style: {
                                                width:
                                                  routerLink.count > 99
                                                    ? "34px"
                                                    : "24px",
                                                height:
                                                  routerLink.count > 99
                                                    ? "34px"
                                                    : "24px",
                                              },
                                              domProps: {
                                                textContent: _vm._s(
                                                  routerLink.count
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                )
              : _c(
                  "ul",
                  { staticClass: "sidebar-menu__list" },
                  [
                    _vm._l(
                      _vm.routerLinkListForAllRestaurants,
                      function (routerLink) {
                        var _obj
                        return [
                          routerLink.visit
                            ? _c(
                                "li",
                                {
                                  key: routerLink.to,
                                  staticClass: "sidebar-menu__item",
                                  class:
                                    ((_obj = {
                                      active: _vm.menuItemIsActive(
                                        routerLink.to
                                      ),
                                    }),
                                    (_obj[routerLink.class] = routerLink.class),
                                    _obj),
                                  attrs: { title: _vm.$t(routerLink.name) },
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "sidebar-menu__link",
                                      attrs: { to: routerLink.to },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "sidebar-menu__img" },
                                        [
                                          _c("svg-icon", {
                                            staticClass: "ic-dashboard",
                                            attrs: {
                                              name: routerLink.svg,
                                              width: "24",
                                              height: "24",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "sidebar-menu__text flex a-center",
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "text",
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.$t(routerLink.name)
                                              ),
                                            },
                                          }),
                                          routerLink.count
                                            ? _c("div", {
                                                staticClass: "count",
                                                style: {
                                                  width:
                                                    routerLink.count > 99
                                                      ? "34px"
                                                      : "24px",
                                                  height:
                                                    routerLink.count > 99
                                                      ? "34px"
                                                      : "24px",
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    routerLink.count
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      }
                    ),
                  ],
                  2
                ),
          ]
        ),
      ]),
      _c("div", { staticClass: "sidebar__content md-none" }, [
        _c("div", { staticClass: "sidebar__logo" }, [
          _c(
            "button",
            {
              staticClass: "sidebar__close",
              on: {
                click: function ($event) {
                  return _vm.$store.commit("toggleSidebar")
                },
              },
            },
            [
              _c("svg-icon", {
                attrs: { name: "ic-close-circle", width: "32", height: "32" },
              }),
            ],
            1
          ),
          _c("a", { attrs: { href: "/" } }, [
            _c("img", {
              attrs: {
                src: _vm.isSidebarOpen
                  ? "/assets/img/Atmos-logo-white.svg"
                  : "/assets/img/Atmos-icon-white.svg",
                alt: "Logo",
              },
            }),
          ]),
        ]),
        _vm.hasSelectedRestaurant
          ? _c("nav", { staticClass: "sidebar-menu disable-scrollbars" }, [
              _c(
                "ul",
                { staticClass: "sidebar-menu__list" },
                [
                  _vm._l(_vm.routerLinkList, function (routerLink) {
                    var _obj
                    return [
                      routerLink.visit
                        ? _c(
                            "li",
                            {
                              key: routerLink.to,
                              staticClass: "sidebar-menu__item",
                              class:
                                ((_obj = {
                                  active: _vm.menuItemIsActive(routerLink.to),
                                }),
                                (_obj[routerLink.class] = routerLink.class),
                                _obj),
                              attrs: { title: _vm.$t(routerLink.name) },
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "sidebar-menu__link",
                                  attrs: { to: routerLink.to },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "sidebar-menu__img" },
                                    [
                                      _c("svg-icon", {
                                        staticClass: "ic-dashboard",
                                        attrs: {
                                          name: routerLink.svg,
                                          width: "24",
                                          height: "24",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "sidebar-menu__text flex a-center",
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "text",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(routerLink.name)
                                          ),
                                        },
                                      }),
                                      routerLink.count
                                        ? _c("div", {
                                            staticClass: "count",
                                            style: {
                                              width:
                                                routerLink.count > 99
                                                  ? "34px"
                                                  : "24px",
                                              height:
                                                routerLink.count > 99
                                                  ? "34px"
                                                  : "24px",
                                            },
                                            domProps: {
                                              textContent: _vm._s(
                                                routerLink.count
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ])
          : _c("nav", { staticClass: "sidebar-menu disable-scrollbars" }, [
              _c(
                "ul",
                { staticClass: "sidebar-menu__list" },
                [
                  _vm._l(
                    _vm.routerLinkListForAllRestaurants,
                    function (routerLink) {
                      var _obj
                      return [
                        routerLink.visit
                          ? _c(
                              "li",
                              {
                                key: routerLink.to,
                                staticClass: "sidebar-menu__item",
                                class:
                                  ((_obj = {
                                    active: _vm.menuItemIsActive(routerLink.to),
                                  }),
                                  (_obj[routerLink.class] = routerLink.class),
                                  _obj),
                                attrs: { title: _vm.$t(routerLink.name) },
                              },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "sidebar-menu__link",
                                    attrs: { to: routerLink.to },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "sidebar-menu__img" },
                                      [
                                        _c("svg-icon", {
                                          staticClass: "ic-dashboard",
                                          attrs: {
                                            name: routerLink.svg,
                                            width: "24",
                                            height: "24",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "sidebar-menu__text flex a-center",
                                      },
                                      [
                                        _c("div", {
                                          staticClass: "text",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(routerLink.name)
                                            ),
                                          },
                                        }),
                                        routerLink.count
                                          ? _c("div", {
                                              staticClass: "count",
                                              style: {
                                                width:
                                                  routerLink.count > 99
                                                    ? "34px"
                                                    : "24px",
                                                height:
                                                  routerLink.count > 99
                                                    ? "34px"
                                                    : "24px",
                                              },
                                              domProps: {
                                                textContent: _vm._s(
                                                  routerLink.count
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    }
                  ),
                ],
                2
              ),
            ]),
        _vm.restaurantId && _vm.checkPermissionPath("start_order_button.view")
          ? _c("section", { staticClass: "btn-new-order-wrap" }, [
              _c(
                "button",
                {
                  staticClass: "flex a-center",
                  class: { opend: _vm.isSidebarOpenOnly },
                  on: { click: _vm.newOrder },
                },
                [
                  _c("svg-icon", {
                    staticClass: "m-i-end-1ch second-fill",
                    attrs: { name: "ic-plus", width: "16", height: "16" },
                  }),
                  _vm.isSidebarOpen
                    ? _c("span", {
                        domProps: { textContent: _vm._s(_vm.$t("New order")) },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }