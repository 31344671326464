var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { loading: _vm.loading } },
    [
      _c("toaster"),
      _c("main-loader"),
      _c("document-events"),
      _c(
        "div",
        {
          staticClass: "wrapper",
          class: {
            "open-sidebar": _vm.isSidebarOpen,
            "open-footer": _vm.footerOpen,
          },
        },
        [
          _vm.hasTopHead
            ? _c(
                "header",
                {
                  staticClass: "header",
                  class: { "catalog-container-header": _vm.inCatalog },
                },
                [
                  _c(
                    "v-main",
                    [_c("router-view", { attrs: { name: "header" } })],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            [
              _c("emergency-dialog"),
              _c("alert-dialog"),
              _c("confirmation-dialog"),
              _c("login-dialog"),
              _c("incoming-call-alert-dialog"),
              _c("order-done-popup"),
              _c("incoming-order-dialog"),
              _c("refund-dialog"),
              _c("user-profile-popup", { attrs: { profile: _vm.profile } }),
              _c("client-details-popup"),
              _vm.clientIsOpened
                ? _c("show-client-before-order", {
                    attrs: { client: _vm.clientIsOpened },
                    on: { close: _vm.clientBeforeOrderPopupClose },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-main",
            {
              staticClass: "layout",
              class: { "catalog-container": _vm.inCatalog },
            },
            [
              _vm.catalogSearch
                ? _c("catalog-editor")
                : _c("router-view", {
                    attrs: {
                      profile: _vm.profile,
                      restaurants: _vm.restaurantsOptions,
                      restaurant: _vm.restaurant,
                      "branch-id": _vm.branchId,
                      branches: _vm.branchesOptions,
                      zones: _vm.zones,
                    },
                  }),
            ],
            1
          ),
          _vm.hasFooter
            ? _c("div", { staticClass: "footer-container" }, [
                _c(
                  "section",
                  {
                    staticClass: "bottom-fixed-container flex a-center md-none",
                  },
                  [
                    _vm.extensionConnected
                      ? _c("keypad", {
                          style: { transform: "translate(-20px, -32px)" },
                        })
                      : _vm.canAssignExtension
                      ? _c("img", {
                          staticClass: "pointer",
                          attrs: {
                            src: require("../assets/img/svg/phone-control.svg"),
                          },
                          on: { click: _vm.sipConnect },
                        })
                      : _vm._e(),
                    _c("img", {
                      staticClass: "pointer",
                      attrs: {
                        hidden: "",
                        src: require("../assets/img/svg/save-btn.svg"),
                      },
                      on: { click: _vm.saveChanges },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("footer", { staticClass: "footer-mob" }, [
            _c("div", { staticClass: "footer-nav" }, [
              _c(
                "div",
                { staticClass: "footer-nav__list" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "footer-nav__item",
                      attrs: { to: _vm.dashboardRoute },
                    },
                    [
                      _c("svg-icon", {
                        attrs: {
                          name: "ic-dashboard",
                          width: "32",
                          height: "24",
                        },
                      }),
                      _c("div", {
                        staticClass: "footer-nav__text",
                        domProps: { textContent: _vm._s(_vm.$t("Dashboard")) },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "footer-nav__item",
                      attrs: { to: _vm.ordersRoute },
                    },
                    [
                      _c("svg-icon", {
                        attrs: { name: "ic-orders", width: "32", height: "24" },
                      }),
                      _c("div", {
                        staticClass: "footer-nav__text",
                        domProps: { textContent: _vm._s(_vm.$t("Orders")) },
                      }),
                    ],
                    1
                  ),
                  _vm.showEmergencyBtn ? _c("emergency-btn") : _vm._e(),
                  _c(
                    "router-link",
                    {
                      staticClass: "footer-nav__item",
                      attrs: { to: _vm.settingsRoute },
                    },
                    [
                      _c("svg-icon", {
                        attrs: {
                          name: "ic-settings",
                          width: "32",
                          height: "24",
                        },
                      }),
                      _c("div", {
                        staticClass: "footer-nav__text",
                        domProps: { textContent: _vm._s(_vm.$t("Settings")) },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm.showEmergencyBtn
            ? _c("emergency-btn", { staticClass: "md-none" })
            : _vm._e(),
          _c("call-panel", {
            attrs: { profile: _vm.profile, restaurant: _vm.restaurant },
            on: { showed: _vm.callPanelShowed, hided: _vm.callPanelHided },
          }),
        ],
        1
      ),
      _c("ipracticom", { attrs: { restaurant: _vm.restaurant } }),
      _c("send-simple-sms-popup"),
      _vm.profile
        ? _c(
            "div",
            {
              staticClass: "wrapper h0 minh0",
              class: {
                "open-sidebar": _vm.isSidebarOpenOnly,
                "open-footer": _vm.footerOpen,
              },
            },
            [_c("router-view", { attrs: { name: "sidebar" } })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }