var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-page" }, [
    _c(
      "div",
      { staticClass: "popup-wrap popup-wrap-full disable-scrollbars p0" },
      [
        _c("div", { staticClass: "popup" }, [
          _c("div", { staticClass: "popup__top" }, [
            _c("img", {
              staticClass: "popup__bg-top d-md-none",
              attrs: { src: "/assets/img/bg-mobile-head.svg", alt: "" },
            }),
            _vm._m(0),
            _c("div", { staticClass: "popup__head" }, [
              _c("div", {
                staticClass: "h1",
                domProps: { textContent: _vm._s(_vm.$t("Sign in")) },
              }),
            ]),
          ]),
          _c("div", { staticClass: "popup__content" }, [
            _c("div", { staticClass: "sign-in-options" }, [
              _c(
                "button",
                {
                  staticClass: "btn",
                  class: { active: _vm.isPhoneSignIn },
                  attrs: { type: "button" },
                  on: { click: _vm.usePhoneSignIn },
                },
                [
                  _c("span", {
                    domProps: { textContent: _vm._s(_vm.$t("Phone")) },
                  }),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "btn",
                  class: { active: _vm.isEmailSignIn },
                  on: { click: _vm.useEmailSignIn },
                },
                [
                  _c("span", {
                    domProps: { textContent: _vm._s(_vm.$t("Email")) },
                  }),
                ]
              ),
            ]),
            _vm.isPhoneSignIn
              ? _c(
                  "form",
                  {
                    staticClass: "form-site-wrap",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.phoneLogin.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "form-site" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "form-site__content form-site__content_center",
                        },
                        [
                          _c("phone-fields", {
                            ref: "phone-input",
                            attrs: { phone: _vm.phone, required: true },
                            on: {
                              "update-phone": function ($event) {
                                _vm.phone = $event
                              },
                              "update-country-code": function ($event) {
                                _vm.countryCode = $event
                              },
                              enter: _vm.phoneLogin,
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "form-site__btn" }, [
                        _c(
                          "button",
                          { staticClass: "btn", attrs: { type: "submit" } },
                          [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(_vm.$t("Sign in")),
                              },
                            }),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm.isEmailSignIn
              ? _c(
                  "form",
                  {
                    staticClass: "form-site-wrap",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.emailLogin.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "form-site" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "form-site__content form-site__content_center",
                        },
                        [
                          _c("div", { staticClass: "form-site__field" }, [
                            _c("div", { staticClass: "form-site__input" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.email,
                                    expression: "email",
                                  },
                                ],
                                ref: "email-input",
                                attrs: {
                                  type: "email",
                                  placeholder: _vm.$t("Email"),
                                  required: "",
                                },
                                domProps: { value: _vm.email },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.email = $event.target.value
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _c("div", { staticClass: "form-site__field" }, [
                            _c("div", { staticClass: "form-site__input" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.password,
                                    expression: "password",
                                  },
                                ],
                                attrs: {
                                  type: "password",
                                  placeholder: _vm.$t("Password"),
                                  required: "",
                                },
                                domProps: { value: _vm.password },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.password = $event.target.value
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "form-site__btn" }, [
                        _c(
                          "button",
                          { staticClass: "btn", attrs: { type: "submit" } },
                          [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(_vm.$t("Sign in")),
                              },
                            }),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
      ]
    ),
    _c("main", [
      _c("section", { staticClass: "page-empty" }, [
        _c("div", { staticClass: "page-empty__copyright" }, [
          _c("span", {
            staticClass: "page-empty__copyright-text",
            domProps: { textContent: _vm._s(_vm.$t("Powered by")) },
          }),
          _c("img", { attrs: { src: "assets/img/Atmos-logo.svg", alt: "" } }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "popup__login d-md-none" }, [
      _c("img", {
        attrs: { src: "/assets/img/Atmos-logo-white.svg", alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }