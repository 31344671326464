var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "availability-tab" }, [
    _c("div", { staticClass: "popup__content" }, [
      _c("div", { staticClass: "form-site__content" }, [
        _c(
          "div",
          { staticClass: "working-days" },
          [
            _c("span", {
              staticClass: "source",
              domProps: { textContent: _vm._s(_vm.$t("Source")) },
            }),
            _c(
              "div",
              { staticClass: "availability-check-list rp5" },
              _vm._l(_vm.sourceEnumValues, function (key) {
                return _c(
                  "label",
                  {
                    key: _vm.sourceEnumToStr(key),
                    staticClass: "availability-check",
                    class: {
                      active: _vm.availability.order_sources.includes(
                        _vm.sourceEnumToNumber(key)
                      ),
                    },
                    style: { width: "100%", height: "65px" },
                  },
                  [
                    _c("input", {
                      attrs: { type: "checkbox" },
                      domProps: {
                        value: _vm.availability.order_sources.includes(
                          _vm.sourceEnumToNumber(key)
                        ),
                      },
                      on: {
                        input: function ($event) {
                          return _vm.setAvailabitySources(key)
                        },
                      },
                    }),
                    _c("svg-icon", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.availability.order_sources.includes(
                            _vm.sourceEnumToNumber(key)
                          ),
                          expression:
                            "\n                availability.order_sources.includes(sourceEnumToNumber(key))\n              ",
                        },
                      ],
                      attrs: {
                        name: "availability-check-v",
                        width: "16",
                        height: "12",
                      },
                    }),
                    _c("svg-icon", {
                      attrs: {
                        name:
                          "ic-availability-" + _vm.sourceEnumToLowerStr(key),
                        width: "24",
                        height: "24",
                      },
                    }),
                    _c("span", {
                      staticClass: "input-check__text",
                      domProps: {
                        textContent: _vm._s(_vm.$t(_vm.sourceEnumToStr(key))),
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
            _c("span", {
              staticClass: "method",
              domProps: { textContent: _vm._s(_vm.$t("Method")) },
            }),
            _c(
              "div",
              { staticClass: "availability-check-list rp5" },
              _vm._l(_vm.typesEnumValues, function (k) {
                return _c(
                  "label",
                  {
                    key: _vm.typeEnumToStr(_vm.typeEnumToNumber(k)),
                    staticClass: "availability-check",
                    class: {
                      active: _vm.availability.order_types.includes(
                        _vm.typeEnumToNumber(k)
                      ),
                    },
                    style: { width: "100%", height: "65px" },
                  },
                  [
                    _c("input", {
                      attrs: { disabled: !_vm.permissions, type: "checkbox" },
                      domProps: {
                        value: _vm.availability.order_sources.includes(
                          _vm.typeEnumToNumber(k)
                        ),
                      },
                      on: {
                        input: function ($event) {
                          _vm.setAvailabityTypes(_vm.typeEnumToNumber(k))
                        },
                      },
                    }),
                    _c("svg-icon", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.availability.order_types.includes(
                            _vm.typeEnumToNumber(k)
                          ),
                          expression:
                            "availability.order_types.includes(typeEnumToNumber(k))",
                        },
                      ],
                      attrs: {
                        name: "availability-check-v",
                        width: "16",
                        height: "12",
                      },
                    }),
                    _c("svg-icon", {
                      attrs: {
                        name: _vm.typeEnumToIcons(k),
                        width: "30",
                        height: "25",
                      },
                    }),
                    _c("span", {
                      staticClass: "input-check__text",
                      domProps: {
                        textContent: _vm._s(_vm.$t(_vm.typeEnumToStr(k))),
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
            _c("form-site-field-select-box", {
              staticClass: "select-box-menus",
              attrs: {
                disabled: !_vm.permissions,
                title: "Menus",
                firstTop: "",
                widthHeader: "200px",
                width: "200px",
                placeholder: _vm.$t("Without association"),
                showSearch: "",
                options: _vm.menusOpt,
              },
              model: {
                value: _vm.availability.menus,
                callback: function ($$v) {
                  _vm.$set(_vm.availability, "menus", $$v)
                },
                expression: "availability.menus",
              },
            }),
            _c("div", { staticClass: "working-days__all" }, [
              _c("div", { staticClass: "input-check" }, [
                _c(
                  "label",
                  { staticClass: "input-check__label" },
                  [
                    _c("checkbox", {
                      attrs: { disabled: !_vm.permissions, name: "name" },
                      model: {
                        value: _vm.availability.times.always_open,
                        callback: function ($$v) {
                          _vm.$set(_vm.availability.times, "always_open", $$v)
                        },
                        expression: "availability.times.always_open",
                      },
                    }),
                    _c("span", {
                      staticClass: "input-check__text",
                      domProps: {
                        textContent: _vm._s(_vm.$t("__Always available")),
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "working-days__list" },
              _vm._l(
                [
                  "sunday",
                  "monday",
                  "tuesday",
                  "wednesday",
                  "thursday",
                  "friday",
                  "saturday",
                ],
                function (day, dayIndex) {
                  return _c("day-working-hours", {
                    key: dayIndex,
                    attrs: {
                      disabled: !_vm.permissions,
                      always: _vm.availability.times.always_open,
                      props: _vm.availability.times.days[day],
                    },
                    on: { save: _vm.saveDay },
                  })
                }
              ),
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }