var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: { width: "840px", title: "Edit permissions" },
      on: { close: _vm.close },
    },
    [
      _c("div", { staticClass: "popup__content" }, [
        _c("div", { staticClass: "form-new-user" }, [
          _c(
            "form",
            {
              staticClass: "edit-permissions-wrap",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.save.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "edit-permissions" },
                [
                  _vm.permissionRestId === -1
                    ? _c("div", { staticClass: "edit-permissions__top" }, [
                        _c(
                          "div",
                          { staticClass: "edit-permissions__field" },
                          [
                            _c("div", {
                              staticClass: "edit-permissions__label",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Restaurant")),
                              },
                            }),
                            _c("select-box", {
                              attrs: {
                                viewId: "",
                                searchByValue: "",
                                showVal: "",
                                options: _vm.restaurantsOptions,
                                showSearch: true,
                                unsort: "",
                              },
                              model: {
                                value: _vm.restaurant_id,
                                callback: function ($$v) {
                                  _vm.restaurant_id = $$v
                                },
                                expression: "restaurant_id",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _c("div", {
                        staticClass: "h2",
                        domProps: { textContent: _vm._s(_vm.restaurantName) },
                      }),
                  _vm.branchesOptions && _vm.branchesOptions.length
                    ? _c("div", { staticClass: "edit-permissions__top" }, [
                        _c(
                          "div",
                          { staticClass: "edit-permissions__field" },
                          [
                            _c("div", {
                              staticClass: "edit-permissions__label",
                              domProps: {
                                textContent: _vm._s(_vm.$t("Branch")),
                              },
                            }),
                            _c("select-box", {
                              attrs: {
                                showVal: "",
                                searchByValue: "",
                                firstTop: "",
                                options: _vm.branchesOptions,
                                showSearch: true,
                              },
                              model: {
                                value: _vm.branch_id,
                                callback: function ($$v) {
                                  _vm.branch_id = $$v
                                },
                                expression: "branch_id",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.showCheckboxes
                    ? [
                        _vm._l(_vm.keywords, function (keyword) {
                          return [
                            _c("policy-checkboxes", {
                              attrs: {
                                policy: _vm.mergedPolicyPermissionsWithOverride,
                                "original-policy": _vm.currentPolicy.policy,
                                reports: _vm.reports,
                                keyword: keyword,
                              },
                              on: { "change-policy": _vm.changePolicy },
                            }),
                          ]
                        }),
                      ]
                    : _c("div", [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "User policy is empty, change policy or set permissions for the default policy"
                              )
                            )
                          ),
                        ]),
                      ]),
                ],
                2
              ),
              _vm.restaurant_id !== -1
                ? _c("div", { staticClass: "edit-permissions__btn" }, [
                    _vm.hasUsersDeletePermission
                      ? _c("button", {
                          staticClass: "btn btn_border",
                          domProps: { textContent: _vm._s(_vm.$t("Delete")) },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.removePermission.apply(null, arguments)
                            },
                          },
                        })
                      : _c("div"),
                    _c(
                      "button",
                      { staticClass: "btn", attrs: { type: "submit" } },
                      [
                        _c("span", {
                          domProps: { textContent: _vm._s(_vm.$t("Save")) },
                        }),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }