var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.show
        ? _c("base-dialog-tabs", {
            staticClass: "edit-user-dialog",
            attrs: {
              width: "540px",
              title: "Policies",
              "overflow-visible": true,
              "tab-list": _vm.tabList,
            },
            on: { close: _vm.close },
            scopedSlots: _vm._u(
              [
                {
                  key: "tab0",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "popup__top" }, [
                        _c("div", { staticClass: "popup__head flex bet" }, [
                          _c("div", {
                            staticClass: "h1 mis0",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  _vm.currentUser.id ? "Edit user" : "New user"
                                )
                              ),
                            },
                          }),
                          _c("div", { staticClass: "form-site__field" }, [
                            _c(
                              "div",
                              { staticClass: "active-container" },
                              [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("Active")),
                                  },
                                }),
                                _c("swich-button", {
                                  attrs: {
                                    disabled:
                                      !_vm.authUserChangeActivePermission,
                                  },
                                  model: {
                                    value: _vm.currentUser.active,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.currentUser, "active", $$v)
                                    },
                                    expression: "currentUser.active",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "popup__content" }, [
                        _c("div", { staticClass: "form-new-user" }, [
                          _c(
                            "form",
                            {
                              staticClass: "form-site-wrap",
                              attrs: { id: _vm.formUniqueId },
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.save.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "form-site" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-site__content" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "form-site__field" },
                                      [
                                        _c("div", {
                                          staticClass: "form-site__label",
                                          domProps: {
                                            textContent: _vm._s(_vm.$t("Name")),
                                          },
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "form-site__input" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.currentUser.name,
                                                  expression:
                                                    "currentUser.name",
                                                },
                                              ],
                                              attrs: { type: "text" },
                                              domProps: {
                                                value: _vm.currentUser.name,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.currentUser,
                                                    "name",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                        _c("input-error", {
                                          attrs: { error: _vm.errors.name },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "form-site__field-wrap" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-site__field" },
                                          [
                                            _c("div", {
                                              staticClass: "form-site__label",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t("Email")
                                                ),
                                              },
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "form-site__input",
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.currentUser.email,
                                                      expression:
                                                        "currentUser.email",
                                                    },
                                                  ],
                                                  attrs: { type: "email" },
                                                  domProps: {
                                                    value:
                                                      _vm.currentUser.email,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.currentUser,
                                                        "email",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c("input-error", {
                                              attrs: {
                                                error: _vm.errors.email,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "form-site__field" },
                                          [
                                            _c("div", {
                                              staticClass: "form-site__label",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t("Phone")
                                                ),
                                              },
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "form-site__input",
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.currentUser.phone,
                                                      expression:
                                                        "currentUser.phone",
                                                    },
                                                  ],
                                                  attrs: { type: "tel" },
                                                  domProps: {
                                                    value:
                                                      _vm.currentUser.phone,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.currentUser,
                                                        "phone",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c("input-error", {
                                              attrs: {
                                                error: _vm.errors.phone,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "form-site__field" },
                                      [
                                        _c("div", {
                                          staticClass: "form-site__label",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("Pincode")
                                            ),
                                          },
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "form-site__input" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.currentUser.pincode,
                                                  expression:
                                                    "currentUser.pincode",
                                                },
                                              ],
                                              attrs: { type: "tel" },
                                              domProps: {
                                                value: _vm.currentUser.pincode,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.currentUser,
                                                    "pincode",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm.currentUser.id && _vm.originalUser.email
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn-icon btn-icon_light-orange",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                _vm.resetPasswordPopup = true
                                              },
                                            },
                                          },
                                          [
                                            _c("span", {
                                              staticClass:
                                                "btn-icon__text pie16 flex a-center",
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t("Reset password")
                                                ),
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "tab1",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "user-policies-list" },
                        [
                          _vm._l(
                            _vm.userPolicies,
                            function (userPolicy, userPolicyIndex) {
                              return _c("atm-card", { key: userPolicyIndex }, [
                                _c(
                                  "div",
                                  {
                                    attrs: { role: "button", tabindex: "0" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editUserPolicy(userPolicy)
                                      },
                                    },
                                  },
                                  [
                                    userPolicy.policy
                                      ? _c("span", {
                                          domProps: {
                                            textContent: _vm._s(
                                              userPolicy.policy.name
                                            ),
                                          },
                                        })
                                      : _c("span", {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "user policy have no policy related"
                                              )
                                            ),
                                          },
                                        }),
                                    _c("br"),
                                    userPolicy.policy
                                      ? _c(
                                          "span",
                                          [
                                            _c("atm-restaurant-name-display", {
                                              attrs: {
                                                "restaurant-id":
                                                  userPolicy.policy.rest_id,
                                              },
                                            }),
                                            _c("span", [_vm._v(" | ")]),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("atm-branch-name-display", {
                                      attrs: {
                                        "branch-id": userPolicy.branch_id,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            }
                          ),
                          _c("atm-add-more-line-btn", {
                            on: { click: _vm.addPolicy },
                          }),
                        ],
                        2
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "btns",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "form-site__btn md-none" },
                        [
                          _vm.currentUser.id &&
                          _vm.checkPermissionPath("users.delete")
                            ? _c("remove-btn", {
                                on: { click: _vm.removeUser },
                              })
                            : _c("div", {}),
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              attrs: { form: _vm.formUniqueId, type: "submit" },
                            },
                            [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(_vm.$t("Save")),
                                },
                              }),
                              _c("svg-icon", {
                                staticClass: "ic-white",
                                attrs: {
                                  name: "ic-arrow-right",
                                  width: "16",
                                  height: "12",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "new-item__btn d-flex d-md-none" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "save-btn",
                              attrs: { type: "submit" },
                            },
                            [
                              _c("svg-icon", {
                                staticClass: "ic-edit",
                                attrs: {
                                  name: "ic-save-btn",
                                  width: "93",
                                  height: "93",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "bin-btn",
                              on: { click: _vm.removeUser },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-bin",
                                  width: "18",
                                  height: "20",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3518168417
            ),
          })
        : _vm._e(),
      _vm.editPolicyIsOpen
        ? _c("edit-user-policy-dialog", {
            attrs: {
              user: _vm.currentUser,
              permissionRestId: _vm.currentPolicyRestaurantId,
              "user-policy": _vm.currentUserPolicy,
            },
            on: { close: _vm.closeEditPolicy, delete: _vm.deleteUserPolicy },
          })
        : _vm._e(),
      _vm.addPolicyIsOpen
        ? _c("add-user-policy-dialog", {
            attrs: { user: _vm.currentUser },
            on: { close: _vm.closeAddPolicy },
          })
        : _vm._e(),
      _vm.resetPasswordPopup
        ? _c("user-reset-password", {
            attrs: { "user-id": _vm.currentUser.id },
            on: { save: _vm.savePassword },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }