var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "smses" },
    [
      _c(
        "table-list",
        _vm._b(
          {
            on: {
              changeOptions: _vm.changeOptions,
              "on-click-line": _vm.editSmsMessageFromLineClick,
            },
            scopedSlots: _vm._u([
              {
                key: "btn-back",
                fn: function () {
                  return [
                    _c(
                      "router-link",
                      {
                        staticClass: "page__back",
                        attrs: { to: _vm.memberClubRoute },
                      },
                      [
                        _c("svg-icon", {
                          attrs: {
                            name: "ic-close-table-arrow",
                            width: "34",
                            height: "34",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "btn",
                fn: function () {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn_pink d-inline-flex",
                        on: { click: _vm.editMessageDialogOpen },
                      },
                      [
                        _c("svg-icon", {
                          attrs: { name: "ic-plus", width: "16", height: "16" },
                        }),
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(_vm.$t("New message")),
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "filter-by-date",
                fn: function () {
                  return [
                    _c("filter-by-date", {
                      model: {
                        value: _vm.dateFilter,
                        callback: function ($$v) {
                          _vm.dateFilter = $$v
                        },
                        expression: "dateFilter",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "table-list",
          _vm.tableListData,
          false
        )
      ),
      _vm.showEditSmsMessageDialog
        ? _c("sms-message-edit-dialog", {
            attrs: {
              "editing-message": _vm.message,
              "loaded-audiences": _vm.audiences,
            },
            on: { close: _vm.editSmsMessageClose },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }