var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "users" },
    [
      _c(
        "table-list",
        _vm._b(
          {
            ref: "usersTable",
            on: {
              "on-click-line": _vm.openPopup,
              changeOptions: _vm.changeOptions,
            },
            scopedSlots: _vm._u(
              [
                _vm.mobile
                  ? {
                      key: "above-tabel",
                      fn: function () {
                        return [
                          _vm.canAddUsers
                            ? _c(
                                "button",
                                {
                                  staticClass: "mobile-btn-plus",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editUser()
                                    },
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      name: "ic-plus",
                                      width: "16",
                                      height: "16",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    }
                  : {
                      key: "btn",
                      fn: function () {
                        return [
                          _vm.canAddUsers
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn_pink",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editUser()
                                    },
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      name: "ic-plus",
                                      width: "16",
                                      height: "16",
                                    },
                                  }),
                                  _c("span", {
                                    staticClass: "xl-none",
                                    domProps: {
                                      textContent: _vm._s(_vm.$t("New user")),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                {
                  key: "filter-by-properties",
                  fn: function () {
                    return [
                      _c("select-box", {
                        staticClass: "user-type",
                        attrs: {
                          maxWidth: "150px",
                          unsort: "",
                          options: _vm.policiesOptions,
                        },
                        model: {
                          value: _vm.policiesFilter,
                          callback: function ($$v) {
                            _vm.policiesFilter = $$v
                          },
                          expression: "policiesFilter",
                        },
                      }),
                      _c(
                        "router-link",
                        {
                          staticClass: "filter__btn btn-circle_red",
                          attrs: { to: _vm.usersPoliciesRoute },
                        },
                        [
                          _c("svg-icon", {
                            attrs: {
                              name: "ic-user-policy",
                              width: "32px",
                              height: "32px",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          },
          "table-list",
          _vm.dataTable,
          false
        )
      ),
      _c("edit-user-dialog", {
        attrs: {
          show: !!_vm.currentUser,
          user: _vm.currentUser,
          errors: _vm.userEditingErrors,
        },
        on: {
          closed: _vm.userEditingClosed,
          saved: _vm.saveUser,
          remove: _vm.removeUser,
        },
      }),
      _c("existing-user-check", {
        attrs: { show: _vm.showExistingUserCheck },
        on: {
          close: function ($event) {
            _vm.showExistingUserCheck = false
          },
          "show-user": _vm.editUser,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }