var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "branches" },
    [
      _c("section", { staticClass: "container" }, [
        _c("div", { staticClass: "page" }, [
          _c("div", { staticClass: "page__main" }, [
            _c(
              "div",
              { staticClass: "settings-branches" },
              [
                _c(
                  "router-link",
                  { staticClass: "page__back", attrs: { to: "../settings" } },
                  [
                    _c("svg-icon", {
                      attrs: {
                        name: "ic-close-table-arrow",
                        width: "34",
                        height: "34",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "branches-head" },
                  [
                    _c("h1", {
                      staticClass: "mb2rem",
                      domProps: { textContent: _vm._s(_vm.$t("Branches")) },
                    }),
                    _vm.mobile
                      ? [
                          _c(
                            "button",
                            {
                              staticClass: "mobile-btn-plus",
                              on: { click: _vm.openNewBranche },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-plus",
                                  width: "16",
                                  height: "16",
                                },
                              }),
                              _c("span", { staticClass: "d-md-block d-none" }),
                            ],
                            1
                          ),
                        ]
                      : [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn_pink",
                              on: { click: _vm.openNewBranche },
                            },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-plus",
                                  width: "16",
                                  height: "16",
                                },
                              }),
                              _c("span", {
                                staticClass: "d-md-block d-none",
                                domProps: {
                                  textContent: _vm._s(_vm.$t("New branch")),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "branch-edit-wrap" },
                  _vm._l(_vm.branches, function (branch) {
                    return _c(
                      "div",
                      {
                        key: branch.id,
                        staticClass: "branch-edit",
                        class: { inactive: !branch.active },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "branch-edit__left-side",
                            style: { "inset-inline-end": "2px" },
                          },
                          [
                            _c("div", { staticClass: "flex" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn-duplicate",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.duplicate(branch)
                                    },
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      name: "ic-document",
                                      width: "20",
                                      height: "20",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            !branch.active
                              ? _c("div", {
                                  staticClass: "branch-edit__inactive",
                                  domProps: {
                                    textContent: _vm._s(_vm.$t("inactive")),
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _c("div", {
                          staticClass: "branch-edit__title",
                          style: { "margin-inline-start": "-9px" },
                          domProps: {
                            textContent: _vm._s(
                              _vm.he ? branch.name : branch.name_en
                            ),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.editBranch(branch)
                            },
                          },
                        }),
                        _c("div", {
                          staticClass: "branch-edit__phone phone",
                          domProps: { textContent: _vm._s(branch.phone) },
                          on: {
                            click: function ($event) {
                              return _vm.editBranch(branch)
                            },
                          },
                        }),
                        _c("div", {
                          staticClass: "branch-edit__address",
                          domProps: {
                            textContent: _vm._s(
                              (_vm.he ? branch.city : branch.city_en) +
                                " " +
                                branch.number +
                                ", " +
                                (_vm.he ? branch.address : branch.address_en)
                            ),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.editBranch(branch)
                            },
                          },
                        }),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm.popup
        ? _c("edit-settings-branch", {
            attrs: { branchInEdit: _vm.branchInEdit },
            on: { save: _vm.save },
          })
        : _vm._e(),
      _vm.catalogCopyDialog
        ? _c("copy-catalog-branch", {
            attrs: { id: _vm.catalogCopyBranchId },
            on: { close: _vm.copyCatalogPopupClose },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }