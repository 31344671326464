var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "button",
        {
          staticClass: "emergency-icon",
          on: { click: _vm.openEmergencyDialog },
        },
        [
          _c(
            "span",
            { staticClass: "relative" },
            [
              _c("emergency-icon", {
                attrs: { emergency: _vm.emergency, isOffMode: "" },
              }),
              _vm.isLoading
                ? _c(
                    "span",
                    { staticClass: "loading-circle" },
                    [_c("loading-circle")],
                    1
                  )
                : _vm.active
                ? _c(
                    "span",
                    {
                      staticClass:
                        "countdown-emergency-wraper flex col a-center j-center",
                    },
                    [
                      _c("show-time", {
                        attrs: { seconds: _vm.range, hides: ["seconds"] },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }