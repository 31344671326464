var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "kms" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("div", { staticClass: "page__main-title" }, [
          _c("h1", { staticClass: "page__title" }, [
            _vm._v(_vm._s(_vm.$t("Kitchen management - KMS"))),
          ]),
        ]),
        _c(
          "v-tabs",
          {
            model: {
              value: _vm.tab,
              callback: function ($$v) {
                _vm.tab = $$v
              },
              expression: "tab",
            },
          },
          [
            _c("v-tab", [_vm._v(_vm._s(_vm.$t("Kitchens")))]),
            _c("v-tab", [_vm._v(_vm._s(_vm.$t("Lines")))]),
          ],
          1
        ),
        _c(
          "v-tabs-items",
          {
            model: {
              value: _vm.tab,
              callback: function ($$v) {
                _vm.tab = $$v
              },
              expression: "tab",
            },
          },
          [
            _c(
              "v-tab-item",
              [
                _c(
                  "table-list",
                  _vm._b(
                    {
                      attrs: {
                        "custom-class": "kms-table",
                        "hide-page-title": true,
                      },
                      on: {
                        "on-click-line": _vm.selectKitchen,
                        "change-options": _vm.kitchensChangeOptions,
                      },
                    },
                    "table-list",
                    _vm.kitchensDataTable,
                    false
                  )
                ),
              ],
              1
            ),
            _c(
              "v-tab-item",
              [
                _c(
                  "table-list",
                  _vm._b(
                    {
                      attrs: {
                        "custom-class": "kms-table",
                        "hide-page-title": true,
                      },
                      on: {
                        "on-click-line": _vm.selectLine,
                        "change-options": _vm.linesChangeOptions,
                      },
                    },
                    "table-list",
                    _vm.linesDataTable,
                    false
                  )
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }