var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar-container" },
    [
      _vm.popup
        ? _c("catalog-editor-edit-category", {
            attrs: { category: _vm.categoryInEdit },
            on: { save: _vm.saveCategory },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "sidebar open", class: { open: _vm.isSidebarOpen } },
        [
          _vm.isSidebarOpen
            ? _c("div", {
                staticClass: "sidebar__background-close",
                on: { click: _vm.closeSideBar },
              })
            : _vm._e(),
          _c("div", { staticClass: "sidebar__content" }, [
            _c(
              "a",
              {
                staticClass: "sidebar__back",
                attrs: { href: "javascript:" },
                on: { click: _vm.goToDashboard },
              },
              [
                _c("svg-icon", {
                  staticClass: "ic-white",
                  attrs: { name: "ic-arrow-left", width: "16", height: "12" },
                }),
                _c("span", {
                  domProps: {
                    textContent: _vm._s(_vm.$t("Exit catalog editor")),
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "sidebar__main" }, [
              _c(
                "nav",
                { staticClass: "sidebar-menu sidebar-menu_dot" },
                [
                  _vm._l(_vm.withoutAssociationCategory, function (category) {
                    return [
                      _c(
                        "li",
                        {
                          key: category.id,
                          staticClass: "sidebar-menu__item",
                          class: {
                            open: !_vm.openedCategories.includes(category.id),
                            active: _vm.currCategoryId === category.id,
                          },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "sidebar-menu__link",
                              on: {
                                click: function ($event) {
                                  return _vm.selectCategory(category)
                                },
                              },
                            },
                            [
                              _c("span", {
                                staticClass: "sidebar-menu__text",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.he ||
                                      !category.name_en ||
                                      category.name_en === "undefined"
                                      ? category.name
                                      : category.name_en
                                  ),
                                },
                              }),
                              category.sub_categories &&
                              category.sub_categories.length
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "sidebar-menu__arr",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.toggleCategory(category.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          name: "ic-arrow-down",
                                          width: "14",
                                          height: "9",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                    ]
                  }),
                  _c(
                    "draggable",
                    {
                      staticClass: "sidebar-menu__list",
                      attrs: {
                        id: "firstDraggable",
                        tag: "ul",
                        handle: ".handle-drag",
                      },
                      on: { end: _vm.moveCategory },
                    },
                    [
                      _vm._l(_vm.parentCategories, function (category) {
                        return category.id !== -1
                          ? [
                              _c(
                                "li",
                                {
                                  key: category.id,
                                  staticClass: "sidebar-menu__item",
                                  class: {
                                    open: !_vm.openedCategories.includes(
                                      category.id
                                    ),
                                    active: _vm.currCategoryId === category.id,
                                  },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "sidebar-menu__link",
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectCategory(category)
                                        },
                                      },
                                    },
                                    [
                                      _vm.dragAndDropEnabled
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "sidebar-menu__img handle-drag",
                                            },
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  name: "ic-double-more",
                                                  width: "8",
                                                  height: "14",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c("span", {
                                        staticClass: "sidebar-menu__text",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.he ||
                                              !category.name_en ||
                                              category.name_en === "undefined"
                                              ? category.name
                                              : category.name_en
                                          ),
                                        },
                                      }),
                                      category.sub_categories &&
                                      category.sub_categories.length
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "sidebar-menu__arr",
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.toggleCategory(
                                                    category.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  name: "ic-arrow-down",
                                                  width: "14",
                                                  height: "9",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  category.sub_categories &&
                                  category.sub_categories.length > 0
                                    ? _c(
                                        "draggable",
                                        _vm._b(
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  !!_vm.openedCategories.includes(
                                                    category.id
                                                  ),
                                                expression:
                                                  "!!openedCategories.includes(category.id)",
                                              },
                                            ],
                                            staticClass:
                                              "sidebar-menu__submenu",
                                            attrs: {
                                              id: "secondDraggable",
                                              tag: "ul",
                                              handle: ".handle-drag-item",
                                            },
                                            on: {
                                              end: function ($event) {
                                                return _vm.moveSubCategory(
                                                  $event,
                                                  category.id
                                                )
                                              },
                                            },
                                          },
                                          "draggable",
                                          _vm.dragOptions,
                                          false
                                        ),
                                        _vm._l(
                                          _vm.getSubCategories(category),
                                          function (subCategory) {
                                            return _c(
                                              "div",
                                              {
                                                key: subCategory.id,
                                                staticClass:
                                                  "sidebar-menu__item",
                                                class: {
                                                  active:
                                                    _vm.currCategoryId ===
                                                    subCategory.id,
                                                },
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "sidebar-menu__link",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.selectCategory(
                                                          subCategory
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm.dragAndDropEnabled
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "sidebar-menu__img handle-drag-item",
                                                          },
                                                          [
                                                            _c("svg-icon", {
                                                              attrs: {
                                                                name: "ic-double-more",
                                                                width: "8",
                                                                height: "14",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c("span", {
                                                      staticClass:
                                                        "sidebar-menu__text",
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.he
                                                            ? subCategory.name
                                                            : subCategory.name_en
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          : _vm._e()
                      }),
                    ],
                    2
                  ),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "sidebar__bottom" },
                [
                  _vm.mobile
                    ? [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn_white",
                            on: { click: _vm.startCreateCategory },
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-plus",
                                width: "16",
                                height: "16",
                              },
                            }),
                            _c("span", {
                              style: { "margin-right": "10px" },
                              domProps: {
                                textContent: _vm._s(_vm.$t("Category")),
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn_white",
                            on: { click: _vm.startCreateCategory },
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                name: "ic-plus",
                                width: "16",
                                height: "16",
                              },
                            }),
                            _c("span", {
                              staticClass: "d-md-block d-none",
                              domProps: {
                                textContent: _vm._s(_vm.$t("New category")),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                ],
                2
              ),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "bottombar d-block d-md-none" }, [
        _c("div", { staticClass: "menu" }, [
          _c(
            "div",
            {
              staticClass: "top-menu__item",
              class: { active: _vm.itemsType === "none" },
              on: {
                click: function ($event) {
                  _vm.itemsType = "none"
                },
              },
            },
            [
              _c("div", {
                staticClass: "top-menu__link",
                domProps: { textContent: _vm._s(_vm.$t("All")) },
              }),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "top-menu__item",
              class: { active: _vm.itemsType === "item" },
              on: {
                click: function ($event) {
                  _vm.itemsType = "item"
                },
              },
            },
            [
              _c("div", {
                staticClass: "top-menu__link",
                domProps: { textContent: _vm._s(_vm.$t("Items")) },
              }),
              _vm.inactiveItems
                ? _c("div", {
                    staticClass: "inactive",
                    domProps: { textContent: _vm._s(_vm.inactiveItems) },
                  })
                : _vm._e(),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "top-menu__item mie64",
              class: { active: _vm.itemsType === "extra" },
              on: {
                click: function ($event) {
                  _vm.itemsType = "extra"
                },
              },
            },
            [
              _c("div", {
                staticClass: "top-menu__link",
                domProps: { textContent: _vm._s(_vm.$t("Addons")) },
              }),
              _vm.inactiveAddons
                ? _c("div", {
                    staticClass: "inactive",
                    domProps: { textContent: _vm._s(_vm.inactiveAddons) },
                  })
                : _vm._e(),
            ]
          ),
          _vm.checkPermissionPath("catalog_editor.items_availability")
            ? _c(
                "div",
                {
                  staticClass: "top-menu__item",
                  class: { active: _vm.itemsType === "menu" },
                  on: {
                    click: function ($event) {
                      _vm.itemsType = "menu"
                    },
                  },
                },
                [
                  _c("div", {
                    staticClass: "top-menu__link",
                    domProps: { textContent: _vm._s(_vm.$t("Menus")) },
                  }),
                  _vm.inactiveMenus
                    ? _c("div", {
                        staticClass: "inactive",
                        domProps: { textContent: _vm._s(_vm.inactiveMenus) },
                      })
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "top-menu__item",
              class: { active: _vm.itemsType === "tag" },
              on: {
                click: function ($event) {
                  _vm.itemsType = "tag"
                },
              },
            },
            [
              _c("div", {
                staticClass: "top-menu__link",
                domProps: { textContent: _vm._s(_vm.$t("Tags")) },
              }),
              _vm.inactiveTags
                ? _c("div", {
                    staticClass: "inactive",
                    domProps: { textContent: _vm._s(_vm.inactiveTags) },
                  })
                : _vm._e(),
            ]
          ),
          _vm.checkPermissionPath("catalog_editor.items_availability")
            ? _c(
                "div",
                {
                  staticClass: "top-menu__item",
                  class: { active: _vm.itemsType === "filter" },
                  on: {
                    click: function ($event) {
                      _vm.itemsType = "filter"
                    },
                  },
                },
                [
                  _c("div", {
                    staticClass: "top-menu__link",
                    domProps: { textContent: _vm._s(_vm.$t("Filters")) },
                  }),
                  _vm.inactiveFilters
                    ? _c("div", {
                        staticClass: "inactive",
                        domProps: { textContent: _vm._s(_vm.inactiveFilters) },
                      })
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ]),
      ]),
      _vm.popup
        ? _c("catalog-editor-edit-category", {
            attrs: { category: _vm.categoryInEdit },
            on: { save: _vm.saveCategory },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }