var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("main", [
      _c("section", { staticClass: "container disable-scrollbars" }, [
        _c("div", { staticClass: "page" }, [
          _c("div", { staticClass: "page__main" }, [
            _c("div", { staticClass: "page__top" }),
            _c("div", { staticClass: "page__content" }, [
              _vm.loading
                ? _c("span", {
                    domProps: {
                      textContent: _vm._s(_vm.$t("Loading order process...")),
                    },
                  })
                : _vm._e(),
              _c("iframe", {
                ref: "order-iframe",
                attrs: { hidden: !_vm.iframeUrl, src: _vm.iframeUrl },
              }),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }