var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "new-item-info" }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
          },
        },
      },
      [
        _c("div", { staticClass: "form-site" }, [
          _c("div", { staticClass: "form-site__content" }, [
            _c("div", { staticClass: "new-item-info__top" }, [
              _c("div", {
                staticClass: "new-item-info__head",
                domProps: {
                  textContent: _vm._s(
                    _vm.he ||
                      !_vm.itemCopy.name_en ||
                      _vm.itemCopy.name_en === "undefined"
                      ? _vm.itemCopy.name
                      : _vm.itemCopy.name_en
                  ),
                },
              }),
            ]),
            _c(
              "div",
              { staticClass: "new-group" },
              [
                _c(
                  "draggable",
                  _vm._b(
                    {
                      staticClass: "new-group__list group",
                      on: { end: _vm.moveExtraCategory },
                    },
                    "draggable",
                    _vm.darggableBind,
                    false
                  ),
                  [
                    _vm._l(
                      _vm.itemCopy.extras_category,
                      function (extraCategory) {
                        return _c(
                          "div",
                          {
                            key: extraCategory.id,
                            staticClass: "new-group-res darggable",
                            on: {
                              click: function ($event) {
                                return _vm.editExtraCategory(extraCategory)
                              },
                            },
                          },
                          [
                            _c("div", {
                              staticClass: "new-group-res__title",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.he || !extraCategory.name_en
                                    ? extraCategory.name
                                    : extraCategory.name_en
                                ),
                              },
                            }),
                            _vm.hasDependency(extraCategory.depend_on)
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "new-group-res__dependency-description",
                                  },
                                  [
                                    extraCategory.depend_on
                                      ? _c("span", [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t("Only show if bought")
                                              ) +
                                              ": " +
                                              _vm._s(
                                                _vm.dependedOn(
                                                  extraCategory.depend_on
                                                )
                                              ) +
                                              "\n                "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "new-group-res__action" },
                              [
                                _c("bin-btn", {
                                  staticClass: "mie20",
                                  attrs: { size: 32 },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeExtraCategory(
                                        extraCategory
                                      )
                                    },
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "new-group-res__menu" },
                                  [
                                    _c("svg-icon", {
                                      attrs: {
                                        name: "ic-double-more",
                                        width: "8",
                                        height: "14",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }
                    ),
                    _c("div", { staticClass: "line-more" }, [
                      _c(
                        "button",
                        {
                          staticClass: "line-more__content",
                          on: { click: _vm.createExtraCategory },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "line-more__icon" },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "ic-plus",
                                  width: "12",
                                  height: "12",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("span", {
                            staticClass: "line-more__text",
                            domProps: {
                              textContent: _vm._s(_vm.$t("New step")),
                            },
                          }),
                        ]
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }